<template>
    <div>
        <p class="font-bold mt-3 text-lg" style="margin-left: 20px">My Programs</p>
        <el-table :data="dataList" style="max-width: 1360px;font-size: 12px;padding: 20px;box-sizing: border-box;"
            empty-text="No Data" :highlight-current-row="false" :highlight-selection-row="false"
            :header-cell-class-name="'custom-head-cell'" :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
            <el-table-column prop="project.project_ename" label="Program"></el-table-column>
            <el-table-column prop="" label="Sign Up Date" width="200px">
                <template slot-scope="scope">
                    <p v-html="convertTimeToEng(scope.row.create_time)"></p>
                    <!-- {{ convertTimeToEng(scope.row.create_time) }} -->
                    <!-- {{ $dayjs(scope.row.create_time * 1000).format("MMM DD,&nbsp;YYYY") }} -->
                </template>
            </el-table-column>
            <el-table-column prop="" label="Status" width="160px">
                <template slot-scope="scope">
                    {{ scope.row.application_status == '待缴费' ? 'Pending Payment' : 'Ongoing' }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="" label="Result" width="160px"></el-table-column> -->
            <el-table-column prop="" label="Operation" width="360px">
                <template slot-scope="scope">
                    <el-button
                        v-if="scope.row.project.project_status !='active' &&scope.row.application_project == '2024AEO' && (scope.row.application_status == '已缴费' || scope.row.application_status == '无需缴费')"
                        type="primary" size="small" @click="gotoAwardView(scope.row)">Certificate</el-button>
                    <el-button type="primary" size="small"
                        v-if="scope.row.application_status != '待缴费' && scope.row.project.eae_submission_show == 'show'"
                        @click="doShowStudentSubmissionDialog(scope.row)">Submit</el-button>
                    <!-- v-if="scope.row.application_status == '待缴费'"-->
                    <div v-if="scope.row.application_status == '待缴费'"
                        style="display: flex;align-items: center;justify-content: center;">
                        <el-button type="primary" size="small"
                            @click="gotoSelectPassView(scope.row._id.$id, scope.row.project.project_ename)">Verify
                            Pass</el-button>
                        <el-button type="primary" size="small" @click="gotoPay(scope.row._id.$id)">Pay</el-button>
                        <el-button type="primary" size="small" @click="doCancel(scope.row._id.$id)">Cancel</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>


        <el-dialog :visible.sync="showStudentSubmissionDialog" width="35%">
            <div style="margin-top: -30px;width: 100%;padding: 0px 20px;">
                <p style="text-align: center;color: #ff6450;font-size: 28px;font-weight: bold;">Video/PDF Submission</p>
                <el-form :model="studentSubmissionInfo" label-position="top" label-width="140px"
                    style="margin-top: 20px;">
                    <el-form-item label="Please input the url">
                        <el-input v-model="studentSubmissionInfo.content_url" style="width: 100%;"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <div class="flex items-center justify-center">
                    <el-button size="medium" @click="showStudentSubmissionDialog = false">Cancel</el-button>
                    <el-button type="primary" size="medium" @click="saveStudentSubmissionInfo()">Submit</el-button>
                </div>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {
    updateStudentSubmission,
    getStudentSubmissionByApplication,
    checkApplication,
    removeApplication,
    getStudentApplications
} from '../../api/eae'
import '../../assets/common/font.css'
import {
    getUserId
} from '../../utils/store'
export default ({
    name: 'index',
    data() {
        return {
            studentSubmissionInfo: {
                id: '',
                application_id: '',
                content_url: ''
            },
            showStudentSubmissionDialog: false,
            dataList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        gotoAwardView(row) {
            this.$router.push({
                path: '/award',
                query: {
                    id: row._id.$id
                }
            })
        },

        saveStudentSubmissionInfo() {
            if (this.studentSubmissionInfo.content_url == '') {
                this.$message.console.warn(('Please input the url'));
                return
            }
            updateStudentSubmission(this.studentSubmissionInfo).then((res) => {
                this.$message.success('Submit success~')
                this.showStudentSubmissionDialog = false
            })
        },

        doShowStudentSubmissionDialog(row) {
            getStudentSubmissionByApplication(row._id.$id).then((res) => {
                let result = res.data.data
                if (result) {
                    this.studentSubmissionInfo = {
                        id: result._id.$id,
                        application_id: row._id.$id,
                        content_url: result.content_value
                    }
                } else {
                    this.studentSubmissionInfo = {
                        id: '',
                        application_id: row._id.$id,
                        content_url: ''
                    }
                }
                this.showStudentSubmissionDialog = true
            })
        },

        fetchData() {
            getStudentApplications(getUserId()).then((res) => {
                this.dataList = res.data.data
            })
        },
        gotoSelectPassView(application_id, project_name) {
            this.$router.push({
                path: '/home/verify-pass',
                query: {
                    application_id: application_id,
                    project_name: project_name
                }
            })
        },

        convertTimeToEng(time) {
            var date = new Date(time * 1000);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + " " + day + ",&nbsp;" + year;
        },

        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },

        doCancel(application_id) {
            this.$confirm("Are you sure you want to cancel this application?", "Cancel Application", { type: "warning", confirmButtonText: "Confirm", })
                .then(() => {
                    removeApplication(application_id).then((res) => {
                        this.$message.success('Application Canceled Successfully')
                        this.fetchData()
                    })
                    this.$emit("remove");
                })
        },
        gotoPay(application_id) {
            if (true) {
                checkApplication(application_id).then((res) => {
                    // let application = res.data.data
                    this.toPayView(application_id)
                    // if (application.application_status == '待缴费') {
                    //     this.toPayView(application_id)
                    // } else {
                    //     this.$message.success('Pay success~')
                    // }
                })
                return
            }
        },
        toPayView(application_id) {
            this.$router.push({
                path: '/home/program-order',
                query: {
                    application_id: application_id
                }
            })
        },
        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: Ubuntu-Bold;'
        },
    }
})
</script>

<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    border: 1px solid white;
    box-sizing: border-box;
    font-family: Ubuntu-Bold;
}
</style>